import React from "react"
import cmptStyles from "../styles/standard.module.css"
import { graphql } from "gatsby"
import RichText from "./shared/richtext"

const Standard = ({ 
    content: { 
        title, 
        subtitle, 
        introText, 
        pageContent
    } 
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="post">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <div className={ cmptStyles.heroText }>
                        <h1 className={ cmptStyles.title }>{ title }</h1>
                        {
                            subtitle && <p className={ cmptStyles.subtitle }>{ subtitle }</p>
                        }
                    </div>
                    
                    <div className={ cmptStyles.text }>
                        {
                            introText && <p className={ cmptStyles.introText }>{ introText.introText }</p>
                        }
                        <RichText content={ pageContent }/>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Standard

export const standardQuery = graphql`
    fragment Standard on ContentfulPageStandard {
        id
        node_locale
        route
        title
        subtitle
        introText {
            introText
        }
        pageContent {
            json
        }
        seoTitle
        seoDescription {
            seoDescription
        }
        seoImage {
            fixed(quality: 80, width: 1920, resizingBehavior: SCALE) {
                src
            }
            description
        }
    }
`